<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸 CTO 李佐凡对话复旦徐云杰教授
              </p>
              <div class="fst-italic mb-2">2022年10月12日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/16/01-合作.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技联合创始人&CTO李佐凡先生与复旦大学管理学院徐云杰教授，就“基于限价单成交概率的预测，构建限价单与市价单的动态混合策略，以提高算法交易绩效”课题项目进行了线上交流探讨。
                  </p>
                   <p>
                    非凸科技作为校企课题项目的参与方，深度支持项目建设和实习指导，为学生提供综合金融与数学、技术与科研、竞争与合作的机会与体验，启发更多学生关注算法策略研究的挑战。
                  </p>
                  <p>
                    交流会上，双方主要在学生培养、项目课题、实践创新、实习细则等方面展开了深入交流。双方一致认为，要综合考虑学科背景及职业方向，为学生提供适配度较高的课题项目，并通过校企之间的产学研良性互动，为学生提供实践、探究的空间和条件，以及零距离接触行业前沿技术的机会，实现校企共赢新局面。
                  </p>
                  <p>
                    此次，是更深层次、更专业领域的校企合作。一方面，复旦大学强大的学科优势，契合经济发展要求，为非凸发展注入磅礴力量；另一方面，双方合作课题项目，将在培养创新型、复合型金融人才方面开创了新方式。
                  </p>
                  <p>
                    非凸科技视人才为最宝贵的财富。不仅为员工提供强大的工程能力、广阔的技术视野、完善的培养体系，还为员工提供有竞争力的薪酬福利体系、安全健康的工作环境以及丰富的员工活动，致力于为员工提供一个实现自我价值和个人梦想的职业平台。
                  </p>
                  <p>
                    期待你的加入，共同探索金融科技的星辰与大海！
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/16/02-证书.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    感谢复旦大学管理学院为我们颁发的证书，也期待后期更多的合作！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News13",
};
</script>

<style></style>
